
import { Options, Vue } from "vue-class-component";
import { Blog } from "@/api/blog-api";
import router from "../router";

@Options({
  props: {
    blog: Object
  }
})
export default class BlogEntry extends Vue {
  blog!: Blog;

  openBlog() {
    router.push({ path: `/blog/${this.blog.slug}` });
  }
}
