
import { Options, Vue } from "vue-class-component";
import ChromeButton from "@/components/ChromeButton.vue"; // @ is an alias to /src

@Options({
  components: {
    ChromeButton
  }
})
export default class Home extends Vue {}
