
import { Options, Vue } from "vue-class-component";
import { Blog, getBlogs } from "@/api/blog-api";
import BlogEntry from "@/components/BlogEntry.vue"; // @ is an alias to /src

@Options({
  components: {
    BlogEntry
  }
})
export default class Blogger extends Vue {
  blogs: Blog[] = [];

  created() {
    getBlogs().then(_blogs => {
      this.blogs = _blogs;
    });
  }
}
