
import { Options, Vue } from "vue-class-component";
import { Blog, getBlog } from "@/api/blog-api";

@Options({
  props: {
    slug: String
  }
})
export default class BlogView extends Vue {
  slug!: string;
  blog: Blog = {
    title: "",
    author: "",
    slug: "",
    date: "",
    content: ""
  };

  created() {
    getBlog(this.slug).then(blog => {
      this.blog = blog;
    });
  }
}
