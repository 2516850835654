
import { Vue } from "vue-class-component";

export default class ChromeButton extends Vue {
  packrExtensionUrl = "https://chrome.google.com/webstore/detail/packr/gnnlocmolfemmglhmnaocjiiecnpapph";

  openStore() {
    window.open(this.packrExtensionUrl, "_blank");
  }
}
